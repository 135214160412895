/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Layout from './src/components/Layout';

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

// export const onServiceWorkerUpdateReady = () => {
//   const answer = window.confirm(
//     `The Summit app has been updated. ` +
//       `Reload to display the latest version?`
//   );

//   if (answer === true) {
//     window.location.reload();
//   }
// };

// exports.onRouteUpdate = () => {
//   navigator.serviceWorker.register('/sw.js').then((reg) => {
//     reg.update();
//   });
// };
