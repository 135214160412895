import React from 'react';
import 'normalize.css';
import styled from 'styled-components';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

const PageStyles = styled.div`
  h1 {
    margin-bottom: 2rem;
    font-size: 3rem;
    color: white;
  }
  h3 {
    color: white;
    margin-bottom: 0.7rem;
  }
`;

export default function Layout({ children }) {
  return (
    <PageStyles>
      <GlobalStyles />
      <Typography />
      {children}
    </PageStyles>
  );
}
